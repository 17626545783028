import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/Modal/Modal"
import CostumeCard from "../components/CostumeCard/CostumeCard"
import { useForm } from "react-hook-form"
import LoadingImage from "../images/loading.svg"
import NoResults from "../images/noResults.svg"
import {
  VideoContainer,
  SingleVideoCotnainer,
} from "../components/common/layout"
import { navigate } from "gatsby"

const Costumes = () => {
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showVModal, setShowVModal] = useState(false)
  const [modalImage, setModalImage] = useState("")
  const [video, setVideo] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async formdata => {
    setLoading(true)
    setStudents([])
    const res = await fetch(
      `/.netlify/functions/getPhotoLinks?phone=${formdata.phone}`
    )
    const data = await res.json()
    setStudents(data.student)
    setLoading(false)
  }

  return (
    <Layout>
      {showModal && (
        <Modal close={() => setShowModal(false)}>
          <div className="p-5 flex justify-center items-start">
            <img
              className="max-w-sm max-h-screen object-contain"
              src={modalImage}
            />
          </div>
        </Modal>
      )}

      <Seo title="Photos" />
      <div className="container py-2 px-5 mx-auto min-h-full">
        <h1 className="text-3xl md:text-5xl text-gray-600 font-bold mt-8 md:my-5">
          Find Photos
        </h1>
        <div className="min-h-screen">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            {!loading && students.length === 0 && (
              <form
                className="flex flex-col  md:flex-col my-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <p className="text-sm text-ascendance pb-1 md:pb-2">
                  Please enter the phone number you have registered at the
                  studio
                </p>
                <div className="flex flex-col md:flex-row items-start">
                  <label className="flex md:items-center flex-col md:flex-row text-sm md:text-lg text-gray-600">
                    Phone Number
                    <span>
                      <input
                        type="tel"
                        className="pl-2 mb-2 md:mb-0 md:ml-2 h-10 rounded-md"
                        name="phone"
                        maxLength="10"
                        placeholder="7862221111"
                        {...register("phone", { required: true })}
                      />
                    </span>
                  </label>

                  <button
                    className="md:ml-3 bg-ascendance text-white px-2 md:px-4 py-2 rounded-md"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </form>
            )}
          </div>
          {loading && (
            <img
              style={{ display: "block", margin: "auto" }}
              alt="loading image animation"
              src={LoadingImage}
            />
          )}
          {loading === false && students.length === 0 && (
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-ascendance text-4xl font-semibold mb-10">
                No Results Found
              </h2>
              <img
                className="w-96"
                style={{ display: "block", margin: "auto" }}
                alt="loading image animation"
                src={NoResults}
              />
            </div>
          )}
          <div className="flex flex-col lg:flex-row space-x-3 mt-5">
            {students.length > 0 &&
              students.map(student => (
                <div className="bg-white rounded p-10 lg:w-80 text-center">
                  <h2 className="text-2xl mb-4 mx-auto">
                    {student.firstName} {student.lastName}
                  </h2>
                  <a
                    href={student.url}
                    target="_blank"
                    className="bg-ascendance text-white py-2 px-4 rounded block text-center"
                  >
                    Get Photos
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Costumes
